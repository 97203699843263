.react-web--admin__container {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: inherit;
  margin: 0;
}

.react-web--admin__container *, .react-web--admin__container :before, .react-web--admin__container :after {
  box-sizing: border-box;
}

.react-web--admin__container hr {
  height: 0;
  color: inherit;
}

.react-web--admin__container abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

.react-web--admin__container b, .react-web--admin__container strong {
  font-weight: bolder;
}

.react-web--admin__container code, .react-web--admin__container kbd, .react-web--admin__container samp, .react-web--admin__container pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

.react-web--admin__container small {
  font-size: 80%;
}

.react-web--admin__container sub, .react-web--admin__container sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.react-web--admin__container sub {
  bottom: -.25em;
}

.react-web--admin__container sup {
  top: -.5em;
}

.react-web--admin__container table {
  text-indent: 0;
  border-color: inherit;
}

.react-web--admin__container button, .react-web--admin__container input, .react-web--admin__container optgroup, .react-web--admin__container select, .react-web--admin__container textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

.react-web--admin__container button, .react-web--admin__container select {
  text-transform: none;
}

.react-web--admin__container button, .react-web--admin__container [type="button"], .react-web--admin__container [type="reset"], .react-web--admin__container [type="submit"] {
  -webkit-appearance: button;
}

.react-web--admin__container ::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.react-web--admin__container :-moz-focusring {
  outline: 1px dotted ButtonText;
}

.react-web--admin__container :-moz-ui-invalid {
  box-shadow: none;
}

.react-web--admin__container legend {
  padding: 0;
}

.react-web--admin__container progress {
  vertical-align: baseline;
}

.react-web--admin__container ::-webkit-inner-spin-button, .react-web--admin__container ::-webkit-outer-spin-button {
  height: auto;
}

.react-web--admin__container [type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

.react-web--admin__container ::-webkit-search-decoration {
  -webkit-appearance: none;
}

.react-web--admin__container ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.react-web--admin__container summary {
  display: list-item;
}

.react-web--admin__container blockquote, .react-web--admin__container dl, .react-web--admin__container dd, .react-web--admin__container h1, .react-web--admin__container h2, .react-web--admin__container h3, .react-web--admin__container h4, .react-web--admin__container h5, .react-web--admin__container h6, .react-web--admin__container hr, .react-web--admin__container figure, .react-web--admin__container p, .react-web--admin__container pre {
  margin: 0;
}

.react-web--admin__container button {
  background-color: #0000;
  background-image: none;
}

.react-web--admin__container fieldset {
  margin: 0;
  padding: 0;
}

.react-web--admin__container ol, .react-web--admin__container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.react-web--admin__container *, .react-web--admin__container :before, .react-web--admin__container :after {
  box-sizing: border-box;
  border: 0 solid;
}

.react-web--admin__container hr {
  border-top-width: 1px;
}

.react-web--admin__container img {
  border-style: solid;
}

.react-web--admin__container textarea {
  resize: vertical;
}

.react-web--admin__container input::placeholder, .react-web--admin__container textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

.react-web--admin__container button, .react-web--admin__container [role="button"] {
  cursor: pointer;
}

.react-web--admin__container table {
  border-collapse: collapse;
}

.react-web--admin__container h1, .react-web--admin__container h2, .react-web--admin__container h3, .react-web--admin__container h4, .react-web--admin__container h5, .react-web--admin__container h6 {
  font-size: inherit;
  font-weight: inherit;
}

.react-web--admin__container a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.react-web--admin__container button, .react-web--admin__container input, .react-web--admin__container optgroup, .react-web--admin__container select, .react-web--admin__container textarea {
  line-height: inherit;
  color: inherit;
  padding: 0;
}

.react-web--admin__container pre, .react-web--admin__container code, .react-web--admin__container kbd, .react-web--admin__container samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.react-web--admin__container img, .react-web--admin__container svg, .react-web--admin__container video, .react-web--admin__container canvas, .react-web--admin__container audio, .react-web--admin__container iframe, .react-web--admin__container embed, .react-web--admin__container object {
  vertical-align: middle;
  display: block;
}

.react-web--admin__container img, .react-web--admin__container video {
  max-width: 100%;
  height: auto;
}

.react-web--admin__container *, .react-web--admin__container :before, .react-web--admin__container :after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.react-web--admin__container {
  width: 100%;
}

[type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}

::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.form-input, .form-textarea, .form-select, .form-multiselect {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.form-input:focus, .form-textarea:focus, .form-select:focus, .form-multiselect:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

.form-input::placeholder, .form-textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

.form-input::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

.form-input::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}

.form-input::-webkit-datetime-edit, .form-input::-webkit-datetime-edit-year-field, .form-input::-webkit-datetime-edit-month-field, .form-input::-webkit-datetime-edit-day-field, .form-input::-webkit-datetime-edit-hour-field, .form-input::-webkit-datetime-edit-minute-field, .form-input::-webkit-datetime-edit-second-field, .form-input::-webkit-datetime-edit-millisecond-field, .form-input::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

.react-web--admin__container .sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.react-web--admin__container .pointer-events-none {
  pointer-events: none;
}

.react-web--admin__container .pointer-events-auto {
  pointer-events: auto;
}

.react-web--admin__container .visible {
  visibility: visible;
}

.react-web--admin__container .static {
  position: static;
}

.react-web--admin__container .fixed {
  position: fixed;
}

.react-web--admin__container .absolute {
  position: absolute;
}

.react-web--admin__container .relative {
  position: relative;
}

.react-web--admin__container .inset-0 {
  inset: 0;
}

.react-web--admin__container .inset-y-0 {
  top: 0;
  bottom: 0;
}

.react-web--admin__container .-top-px {
  top: -1px;
}

.react-web--admin__container .left-0 {
  left: 0;
}

.react-web--admin__container .left-4 {
  left: 1rem;
}

.react-web--admin__container .left-6 {
  left: 1.5rem;
}

.react-web--admin__container .right-0 {
  right: 0;
}

.react-web--admin__container .right-6 {
  right: 1.5rem;
}

.react-web--admin__container .top-1\/2 {
  top: 50%;
}

.react-web--admin__container .top-6 {
  top: 1.5rem;
}

.react-web--admin__container .top-8 {
  top: 2rem;
}

.react-web--admin__container .\!z-0 {
  z-index: 0 !important;
}

.react-web--admin__container .z-10 {
  z-index: 10;
}

.react-web--admin__container .z-50 {
  z-index: 50;
}

.react-web--admin__container .col-span-1 {
  grid-column: span 1 / span 1;
}

.react-web--admin__container .col-span-2 {
  grid-column: span 2 / span 2;
}

.react-web--admin__container .-m-px {
  margin: -1px;
}

.react-web--admin__container .-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.react-web--admin__container .-mx-7 {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}

.react-web--admin__container .-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.react-web--admin__container .-my-3 {
  margin-top: -.75rem;
  margin-bottom: -.75rem;
}

.react-web--admin__container .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.react-web--admin__container .my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.react-web--admin__container .my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.react-web--admin__container .-mb-8 {
  margin-bottom: -2rem;
}

.react-web--admin__container .-mb-px {
  margin-bottom: -1px;
}

.react-web--admin__container .-ml-0 {
  margin-left: 0;
}

.react-web--admin__container .-ml-0\.5 {
  margin-left: -.125rem;
}

.react-web--admin__container .-ml-1 {
  margin-left: -.25rem;
}

.react-web--admin__container .-ml-4 {
  margin-left: -1rem;
}

.react-web--admin__container .-mr-1 {
  margin-right: -.25rem;
}

.react-web--admin__container .-mr-2 {
  margin-right: -.5rem;
}

.react-web--admin__container .-mr-2\.5 {
  margin-right: -.625rem;
}

.react-web--admin__container .-mt-12 {
  margin-top: -3rem;
}

.react-web--admin__container .-mt-2 {
  margin-top: -.5rem;
}

.react-web--admin__container .-mt-4 {
  margin-top: -1rem;
}

.react-web--admin__container .mb-10 {
  margin-bottom: 2.5rem;
}

.react-web--admin__container .mb-2 {
  margin-bottom: .5rem;
}

.react-web--admin__container .mb-24 {
  margin-bottom: 6rem;
}

.react-web--admin__container .mb-3 {
  margin-bottom: .75rem;
}

.react-web--admin__container .mb-4 {
  margin-bottom: 1rem;
}

.react-web--admin__container .mb-6 {
  margin-bottom: 1.5rem;
}

.react-web--admin__container .mb-8 {
  margin-bottom: 2rem;
}

.react-web--admin__container .ml-1 {
  margin-left: .25rem;
}

.react-web--admin__container .ml-12 {
  margin-left: 3rem;
}

.react-web--admin__container .ml-2 {
  margin-left: .5rem;
}

.react-web--admin__container .ml-3 {
  margin-left: .75rem;
}

.react-web--admin__container .ml-4 {
  margin-left: 1rem;
}

.react-web--admin__container .ml-6 {
  margin-left: 1.5rem;
}

.react-web--admin__container .ml-8 {
  margin-left: 2rem;
}

.react-web--admin__container .ml-auto {
  margin-left: auto;
}

.react-web--admin__container .mr-1 {
  margin-right: .25rem;
}

.react-web--admin__container .mr-2 {
  margin-right: .5rem;
}

.react-web--admin__container .mr-3 {
  margin-right: .75rem;
}

.react-web--admin__container .mr-4 {
  margin-right: 1rem;
}

.react-web--admin__container .mr-8 {
  margin-right: 2rem;
}

.react-web--admin__container .mt-0 {
  margin-top: 0;
}

.react-web--admin__container .mt-0\.5 {
  margin-top: .125rem;
}

.react-web--admin__container .mt-1 {
  margin-top: .25rem;
}

.react-web--admin__container .mt-10 {
  margin-top: 2.5rem;
}

.react-web--admin__container .mt-12 {
  margin-top: 3rem;
}

.react-web--admin__container .mt-2 {
  margin-top: .5rem;
}

.react-web--admin__container .mt-3 {
  margin-top: .75rem;
}

.react-web--admin__container .mt-4 {
  margin-top: 1rem;
}

.react-web--admin__container .mt-5 {
  margin-top: 1.25rem;
}

.react-web--admin__container .mt-6 {
  margin-top: 1.5rem;
}

.react-web--admin__container .mt-8 {
  margin-top: 2rem;
}

.react-web--admin__container .line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.react-web--admin__container .block {
  display: block;
}

.react-web--admin__container .inline-block {
  display: inline-block;
}

.react-web--admin__container .inline {
  display: inline;
}

.react-web--admin__container .flex {
  display: flex;
}

.react-web--admin__container .inline-flex {
  display: inline-flex;
}

.react-web--admin__container .table {
  display: table;
}

.react-web--admin__container .flow-root {
  display: flow-root;
}

.react-web--admin__container .grid {
  display: grid;
}

.react-web--admin__container .hidden {
  display: none;
}

.react-web--admin__container .h-10 {
  height: 2.5rem;
}

.react-web--admin__container .h-12 {
  height: 3rem;
}

.react-web--admin__container .h-16 {
  height: 4rem;
}

.react-web--admin__container .h-2 {
  height: .5rem;
}

.react-web--admin__container .h-3 {
  height: .75rem;
}

.react-web--admin__container .h-4 {
  height: 1rem;
}

.react-web--admin__container .h-5 {
  height: 1.25rem;
}

.react-web--admin__container .h-6 {
  height: 1.5rem;
}

.react-web--admin__container .h-8 {
  height: 2rem;
}

.react-web--admin__container .h-full {
  height: 100%;
}

.react-web--admin__container .h-px {
  height: 1px;
}

.react-web--admin__container .max-h-60 {
  max-height: 15rem;
}

.react-web--admin__container .max-h-80 {
  max-height: 20rem;
}

.react-web--admin__container .max-h-96 {
  max-height: 24rem;
}

.react-web--admin__container .min-h-full {
  min-height: 100%;
}

.react-web--admin__container .w-0 {
  width: 0;
}

.react-web--admin__container .w-0\.5 {
  width: .125rem;
}

.react-web--admin__container .w-1\/2 {
  width: 50%;
}

.react-web--admin__container .w-10 {
  width: 2.5rem;
}

.react-web--admin__container .w-12 {
  width: 3rem;
}

.react-web--admin__container .w-16 {
  width: 4rem;
}

.react-web--admin__container .w-2 {
  width: .5rem;
}

.react-web--admin__container .w-3 {
  width: .75rem;
}

.react-web--admin__container .w-4 {
  width: 1rem;
}

.react-web--admin__container .w-5 {
  width: 1.25rem;
}

.react-web--admin__container .w-6 {
  width: 1.5rem;
}

.react-web--admin__container .w-8 {
  width: 2rem;
}

.react-web--admin__container .w-96 {
  width: 24rem;
}

.react-web--admin__container .w-auto {
  width: auto;
}

.react-web--admin__container .w-full {
  width: 100%;
}

.react-web--admin__container .w-screen {
  width: 100vw;
}

.react-web--admin__container .min-w-0 {
  min-width: 0;
}

.react-web--admin__container .min-w-\[12rem\] {
  min-width: 12rem;
}

.react-web--admin__container .min-w-full {
  min-width: 100%;
}

.react-web--admin__container .min-w-max {
  min-width: max-content;
}

.react-web--admin__container .max-w-2xl {
  max-width: 42rem;
}

.react-web--admin__container .max-w-full {
  max-width: 100%;
}

.react-web--admin__container .max-w-md {
  max-width: 28rem;
}

.react-web--admin__container .max-w-prose {
  max-width: 65ch;
}

.react-web--admin__container .max-w-xl {
  max-width: 36rem;
}

.react-web--admin__container .flex-1 {
  flex: 1;
}

.react-web--admin__container .flex-auto {
  flex: auto;
}

.react-web--admin__container .flex-none {
  flex: none;
}

.react-web--admin__container .flex-shrink-0, .react-web--admin__container .shrink-0 {
  flex-shrink: 0;
}

.react-web--admin__container .flex-grow {
  flex-grow: 1;
}

.react-web--admin__container .table-fixed {
  table-layout: fixed;
}

.react-web--admin__container .origin-top-right {
  transform-origin: 100% 0;
}

.react-web--admin__container .translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--admin__container .translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--admin__container .rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--admin__container .scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--admin__container .scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--admin__container .scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--admin__container .scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--admin__container .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--admin__container .transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.react-web--admin__container .animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.react-web--admin__container .cursor-default {
  cursor: default;
}

.react-web--admin__container .cursor-not-allowed {
  cursor: not-allowed;
}

.react-web--admin__container .cursor-pointer {
  cursor: pointer;
}

.react-web--admin__container .select-none {
  -webkit-user-select: none;
  user-select: none;
}

.react-web--admin__container .resize-none {
  resize: none;
}

.react-web--admin__container .scroll-py-2 {
  scroll-padding-top: .5rem;
  scroll-padding-bottom: .5rem;
}

.react-web--admin__container .appearance-none {
  appearance: none;
}

.react-web--admin__container .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.react-web--admin__container .flex-row {
  flex-direction: row;
}

.react-web--admin__container .flex-row-reverse {
  flex-direction: row-reverse;
}

.react-web--admin__container .flex-col {
  flex-direction: column;
}

.react-web--admin__container .flex-wrap {
  flex-wrap: wrap;
}

.react-web--admin__container .items-start {
  align-items: flex-start;
}

.react-web--admin__container .items-center {
  align-items: center;
}

.react-web--admin__container .justify-start {
  justify-content: flex-start;
}

.react-web--admin__container .justify-end {
  justify-content: flex-end;
}

.react-web--admin__container .justify-center {
  justify-content: center;
}

.react-web--admin__container .justify-between {
  justify-content: space-between;
}

.react-web--admin__container .gap-6 {
  gap: 1.5rem;
}

.react-web--admin__container .gap-x-2 {
  column-gap: .5rem;
}

.react-web--admin__container .gap-x-3 {
  column-gap: .75rem;
}

.react-web--admin__container .gap-x-4 {
  column-gap: 1rem;
}

.react-web--admin__container .gap-x-6 {
  column-gap: 1.5rem;
}

.react-web--admin__container .gap-x-8 {
  column-gap: 2rem;
}

.react-web--admin__container .gap-y-10 {
  row-gap: 2.5rem;
}

.react-web--admin__container .gap-y-16 {
  row-gap: 4rem;
}

.react-web--admin__container .gap-y-4 {
  row-gap: 1rem;
}

.react-web--admin__container .gap-y-6 {
  row-gap: 1.5rem;
}

.react-web--admin__container .gap-y-8 {
  row-gap: 2rem;
}

.react-web--admin__container :is(.space-x-3 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.react-web--admin__container :is(.space-x-4 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.react-web--admin__container :is(.space-x-8 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.react-web--admin__container :is(.space-y-1 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.react-web--admin__container :is(.divide-y > :not([hidden]) ~ :not([hidden])) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.react-web--admin__container :is(.divide-gray-100 > :not([hidden]) ~ :not([hidden])) {
  --tw-divide-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-divide-opacity));
}

.react-web--admin__container :is(.divide-gray-200 > :not([hidden]) ~ :not([hidden])) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.react-web--admin__container :is(.divide-gray-300 > :not([hidden]) ~ :not([hidden])) {
  --tw-divide-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity));
}

.react-web--admin__container .overflow-auto {
  overflow: auto;
}

.react-web--admin__container .overflow-hidden {
  overflow: hidden;
}

.react-web--admin__container .overflow-x-auto {
  overflow-x: auto;
}

.react-web--admin__container .overflow-y-auto {
  overflow-y: auto;
}

.react-web--admin__container .overflow-y-scroll {
  overflow-y: scroll;
}

.react-web--admin__container .truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-web--admin__container .text-ellipsis {
  text-overflow: ellipsis;
}

.react-web--admin__container .whitespace-nowrap {
  white-space: nowrap;
}

.react-web--admin__container .\!rounded-none {
  border-radius: 0 !important;
}

.react-web--admin__container .rounded {
  border-radius: .25rem;
}

.react-web--admin__container .rounded-2xl {
  border-radius: 1rem;
}

.react-web--admin__container .rounded-full {
  border-radius: 9999px;
}

.react-web--admin__container .rounded-lg {
  border-radius: .5rem;
}

.react-web--admin__container .rounded-md {
  border-radius: .375rem;
}

.react-web--admin__container .rounded-sm {
  border-radius: .125rem;
}

.react-web--admin__container .rounded-xl {
  border-radius: .75rem;
}

.react-web--admin__container .\!border-0 {
  border-width: 0 !important;
}

.react-web--admin__container .border {
  border-width: 1px;
}

.react-web--admin__container .border-0 {
  border-width: 0;
}

.react-web--admin__container .border-2 {
  border-width: 2px;
}

.react-web--admin__container .border-\[0\.5px\] {
  border-width: .5px;
}

.react-web--admin__container .border-b {
  border-bottom-width: 1px;
}

.react-web--admin__container .border-b-2 {
  border-bottom-width: 2px;
}

.react-web--admin__container .border-t {
  border-top-width: 1px;
}

.react-web--admin__container .border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity));
}

.react-web--admin__container .border-emerald-300 {
  --tw-border-opacity: 1;
  border-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.react-web--admin__container .border-emerald-400 {
  --tw-border-opacity: 1;
  border-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.react-web--admin__container .border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.react-web--admin__container .border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.react-web--admin__container .border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.react-web--admin__container .border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.react-web--admin__container .border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.react-web--admin__container .border-transparent {
  border-color: #0000;
}

.react-web--admin__container .border-violet-400 {
  --tw-border-opacity: 1;
  border-color: rgb(167 139 250 / var(--tw-border-opacity));
}

.react-web--admin__container .border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.react-web--admin__container .border-opacity-5 {
  --tw-border-opacity: .05;
}

.react-web--admin__container .\!bg-emerald-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity)) !important;
}

.react-web--admin__container .\!bg-emerald-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity)) !important;
}

.react-web--admin__container .\!bg-teal-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity)) !important;
}

.react-web--admin__container .\!bg-violet-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(167 139 250 / var(--tw-bg-opacity)) !important;
}

.react-web--admin__container .bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.react-web--admin__container .bg-emerald-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity));
}

.react-web--admin__container .bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.react-web--admin__container .bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.react-web--admin__container .bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.react-web--admin__container .bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.react-web--admin__container .bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.react-web--admin__container .bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

.react-web--admin__container .bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.react-web--admin__container .bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.react-web--admin__container .bg-transparent {
  background-color: #0000;
}

.react-web--admin__container .bg-violet-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

.react-web--admin__container .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.react-web--admin__container .bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.react-web--admin__container .object-cover {
  object-fit: cover;
}

.react-web--admin__container .p-10 {
  padding: 2.5rem;
}

.react-web--admin__container .p-12 {
  padding: 3rem;
}

.react-web--admin__container .p-2 {
  padding: .5rem;
}

.react-web--admin__container .p-3 {
  padding: .75rem;
}

.react-web--admin__container .p-4 {
  padding: 1rem;
}

.react-web--admin__container .p-6 {
  padding: 1.5rem;
}

.react-web--admin__container .p-8 {
  padding: 2rem;
}

.react-web--admin__container .px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.react-web--admin__container .px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.react-web--admin__container .px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.react-web--admin__container .px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.react-web--admin__container .px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.react-web--admin__container .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.react-web--admin__container .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.react-web--admin__container .px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.react-web--admin__container .py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.react-web--admin__container .py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.react-web--admin__container .py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.react-web--admin__container .py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.react-web--admin__container .py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.react-web--admin__container .py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.react-web--admin__container .py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.react-web--admin__container .py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.react-web--admin__container .py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.react-web--admin__container .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.react-web--admin__container .py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.react-web--admin__container .py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.react-web--admin__container .py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.react-web--admin__container .pb-10 {
  padding-bottom: 2.5rem;
}

.react-web--admin__container .pb-2 {
  padding-bottom: .5rem;
}

.react-web--admin__container .pb-24 {
  padding-bottom: 6rem;
}

.react-web--admin__container .pb-4 {
  padding-bottom: 1rem;
}

.react-web--admin__container .pb-6 {
  padding-bottom: 1.5rem;
}

.react-web--admin__container .pb-8 {
  padding-bottom: 2rem;
}

.react-web--admin__container .pl-10 {
  padding-left: 2.5rem;
}

.react-web--admin__container .pl-14 {
  padding-left: 3.5rem;
}

.react-web--admin__container .pl-3 {
  padding-left: .75rem;
}

.react-web--admin__container .pl-4 {
  padding-left: 1rem;
}

.react-web--admin__container .pl-7 {
  padding-left: 1.75rem;
}

.react-web--admin__container .pr-1 {
  padding-right: .25rem;
}

.react-web--admin__container .pr-10 {
  padding-right: 2.5rem;
}

.react-web--admin__container .pr-2 {
  padding-right: .5rem;
}

.react-web--admin__container .pr-3 {
  padding-right: .75rem;
}

.react-web--admin__container .pr-4 {
  padding-right: 1rem;
}

.react-web--admin__container .pr-9 {
  padding-right: 2.25rem;
}

.react-web--admin__container .pt-12 {
  padding-top: 3rem;
}

.react-web--admin__container .pt-2 {
  padding-top: .5rem;
}

.react-web--admin__container .pt-5 {
  padding-top: 1.25rem;
}

.react-web--admin__container .pt-6 {
  padding-top: 1.5rem;
}

.react-web--admin__container .text-left {
  text-align: left;
}

.react-web--admin__container .text-center {
  text-align: center;
}

.react-web--admin__container .text-right {
  text-align: right;
}

.react-web--admin__container .align-middle {
  vertical-align: middle;
}

.react-web--admin__container .font-bold {
  font-family: Roboto-Bold, Roboto;
}

.react-web--admin__container .font-light {
  font-family: Roboto-Light, Roboto;
}

.react-web--admin__container .font-regular {
  font-family: Roboto-Regular, Roboto;
}

.react-web--admin__container .font-semi {
  font-family: Roboto-Semi, Roboto;
}

.react-web--admin__container .text-2xl {
  font-size: 1.5rem;
}

.react-web--admin__container .text-3xl {
  font-size: 1.875rem;
}

.react-web--admin__container .text-\[32px\] {
  font-size: 32px;
}

.react-web--admin__container .text-base {
  font-size: 1rem;
}

.react-web--admin__container .text-lg {
  font-size: 1.125rem;
}

.react-web--admin__container .text-sm {
  font-size: .875rem;
}

.react-web--admin__container .text-xl {
  font-size: 1.25rem;
}

.react-web--admin__container .text-xs {
  font-size: .75rem;
}

.react-web--admin__container .font-bold {
  font-weight: 700;
}

.react-web--admin__container .font-light {
  font-weight: 300;
}

.react-web--admin__container .font-medium {
  font-weight: 500;
}

.react-web--admin__container .font-normal {
  font-weight: 400;
}

.react-web--admin__container .font-semibold {
  font-weight: 600;
}

.react-web--admin__container .uppercase {
  text-transform: uppercase;
}

.react-web--admin__container .leading-5 {
  line-height: 1.25rem;
}

.react-web--admin__container .leading-6 {
  line-height: 1.5rem;
}

.react-web--admin__container .leading-7 {
  line-height: 1.75rem;
}

.react-web--admin__container .leading-8 {
  line-height: 2rem;
}

.react-web--admin__container .leading-9 {
  line-height: 2.25rem;
}

.react-web--admin__container .leading-\[1\.15\] {
  line-height: 1.15;
}

.react-web--admin__container .tracking-tight {
  letter-spacing: -.025em;
}

.react-web--admin__container .tracking-widest {
  letter-spacing: .1em;
}

.react-web--admin__container .text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.react-web--admin__container .text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.react-web--admin__container .text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.react-web--admin__container .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.react-web--admin__container .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.react-web--admin__container .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.react-web--admin__container .text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.react-web--admin__container .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.react-web--admin__container .text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.react-web--admin__container .text-inherit {
  color: inherit;
}

.react-web--admin__container .text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.react-web--admin__container .text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.react-web--admin__container .text-violet-700 {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity));
}

.react-web--admin__container .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.react-web--admin__container .opacity-0 {
  opacity: 0;
}

.react-web--admin__container .opacity-100 {
  opacity: 1;
}

.react-web--admin__container .opacity-20 {
  opacity: .2;
}

.react-web--admin__container .opacity-50 {
  opacity: .5;
}

.react-web--admin__container .shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.react-web--admin__container .shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.react-web--admin__container .shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.react-web--admin__container .shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.react-web--admin__container .shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.react-web--admin__container .ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.react-web--admin__container .ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.react-web--admin__container .ring-8 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.react-web--admin__container .ring-inset {
  --tw-ring-inset: inset;
}

.react-web--admin__container .ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.react-web--admin__container .ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

.react-web--admin__container .ring-gray-500\/10 {
  --tw-ring-color: #6b72801a;
}

.react-web--admin__container .ring-gray-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity));
}

.react-web--admin__container .ring-gray-900\/10 {
  --tw-ring-color: #1118271a;
}

.react-web--admin__container .ring-gray-900\/5 {
  --tw-ring-color: #1118270d;
}

.react-web--admin__container .ring-green-600\/20 {
  --tw-ring-color: #16a34a33;
}

.react-web--admin__container .ring-red-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity));
}

.react-web--admin__container .ring-slate-900\/10 {
  --tw-ring-color: #0f172a1a;
}

.react-web--admin__container .ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.react-web--admin__container .ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.react-web--admin__container .drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.react-web--admin__container .grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.react-web--admin__container .filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.react-web--admin__container .transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.react-web--admin__container .transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.react-web--admin__container .duration-100 {
  transition-duration: .1s;
}

.react-web--admin__container .duration-150 {
  transition-duration: .15s;
}

.react-web--admin__container .duration-300 {
  transition-duration: .3s;
}

.react-web--admin__container .duration-500 {
  transition-duration: .5s;
}

.react-web--admin__container .duration-75 {
  transition-duration: 75ms;
}

.react-web--admin__container .ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.react-web--admin__container .ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.react-web--admin__container .ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.react-web--admin__container .placeholder\:text-gray-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.react-web--admin__container .last\:border-0:last-child {
  border-width: 0;
}

.react-web--admin__container .focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.react-web--admin__container .focus-within\:ring-inset:focus-within {
  --tw-ring-inset: inset;
}

.react-web--admin__container .hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.react-web--admin__container .hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.react-web--admin__container .hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.react-web--admin__container .hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.react-web--admin__container .hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.react-web--admin__container .hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.react-web--admin__container .hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.react-web--admin__container .hover\:bg-opacity-90:hover {
  --tw-bg-opacity: .9;
}

.react-web--admin__container .hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.react-web--admin__container .hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.react-web--admin__container .hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.react-web--admin__container .focus\:border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity));
}

.react-web--admin__container .focus\:border-blue-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

.react-web--admin__container .focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.react-web--admin__container .focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.react-web--admin__container .focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.react-web--admin__container .focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.react-web--admin__container .focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

.react-web--admin__container .focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

.react-web--admin__container .focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.react-web--admin__container .focus\:ring-offset-gray-900:focus {
  --tw-ring-offset-color: #111827;
}

.react-web--admin__container .focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.react-web--admin__container .focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.react-web--admin__container .focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.react-web--admin__container .focus-visible\:outline-red-600:focus-visible {
  outline-color: #dc2626;
}

.react-web--admin__container .active\:bg-blue-700:active {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.react-web--admin__container .active\:text-white:active {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.react-web--admin__container .disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.react-web--admin__container .disabled\:bg-white:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.react-web--admin__container .disabled\:text-gray-500:disabled {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.react-web--admin__container .disabled\:text-gray-600:disabled {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.react-web--admin__container .disabled\:opacity-30:disabled {
  opacity: .3;
}

.react-web--admin__container .disabled\:hover\:bg-white:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.react-web--admin__container :is(.group:hover .group-hover\:text-gray-400) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.react-web--admin__container :is(.group:hover .group-hover\:text-gray-500) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.react-web--admin__container :is(.group:hover .group-hover\:text-gray-600) {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

@media (width >= 640px) {
  .react-web--admin__container .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .react-web--admin__container .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .react-web--admin__container .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .react-web--admin__container .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .react-web--admin__container .sm\:ml-16 {
    margin-left: 4rem;
  }

  .react-web--admin__container .sm\:mt-0 {
    margin-top: 0;
  }

  .react-web--admin__container .sm\:mt-12 {
    margin-top: 3rem;
  }

  .react-web--admin__container .sm\:flex {
    display: flex;
  }

  .react-web--admin__container .sm\:table-cell {
    display: table-cell;
  }

  .react-web--admin__container .sm\:grid {
    display: grid;
  }

  .react-web--admin__container .sm\:hidden {
    display: none;
  }

  .react-web--admin__container .sm\:w-12 {
    width: 3rem;
  }

  .react-web--admin__container .sm\:flex-auto {
    flex: auto;
  }

  .react-web--admin__container .sm\:flex-none {
    flex: none;
  }

  .react-web--admin__container .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .react-web--admin__container .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .react-web--admin__container .sm\:items-center {
    align-items: center;
  }

  .react-web--admin__container .sm\:gap-4 {
    gap: 1rem;
  }

  .react-web--admin__container .sm\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .react-web--admin__container .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .react-web--admin__container .sm\:rounded-xl {
    border-radius: .75rem;
  }

  .react-web--admin__container .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .react-web--admin__container .sm\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .react-web--admin__container .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .react-web--admin__container .sm\:pl-3 {
    padding-left: .75rem;
  }

  .react-web--admin__container .sm\:pl-6 {
    padding-left: 1.5rem;
  }

  .react-web--admin__container .sm\:pr-6 {
    padding-right: 1.5rem;
  }

  .react-web--admin__container .sm\:pt-12 {
    padding-top: 3rem;
  }

  .react-web--admin__container .sm\:text-2xl {
    font-size: 1.5rem;
  }

  .react-web--admin__container .sm\:text-3xl {
    font-size: 1.875rem;
  }

  .react-web--admin__container .sm\:text-4xl {
    font-size: 2.25rem;
  }

  .react-web--admin__container .sm\:text-sm {
    font-size: .875rem;
  }

  .react-web--admin__container .sm\:leading-5 {
    line-height: 1.25rem;
  }

  .react-web--admin__container .sm\:leading-6 {
    line-height: 1.5rem;
  }

  .react-web--admin__container .sm\:tracking-tight {
    letter-spacing: -.025em;
  }

  .react-web--admin__container .sm\:duration-700 {
    transition-duration: .7s;
  }
}

@media (width >= 768px) {
  .react-web--admin__container .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .react-web--admin__container .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .react-web--admin__container .md\:ml-4 {
    margin-left: 1rem;
  }

  .react-web--admin__container .md\:mt-0 {
    margin-top: 0;
  }

  .react-web--admin__container .md\:flex {
    display: flex;
  }

  .react-web--admin__container .md\:w-1\/2 {
    width: 50%;
  }

  .react-web--admin__container .md\:w-full {
    width: 100%;
  }

  .react-web--admin__container .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .react-web--admin__container .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .react-web--admin__container .md\:items-center {
    align-items: center;
  }

  .react-web--admin__container .md\:justify-between {
    justify-content: space-between;
  }
}

@media (width >= 1024px) {
  .react-web--admin__container .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .react-web--admin__container .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .react-web--admin__container .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .react-web--admin__container .lg\:mb-12 {
    margin-bottom: 3rem;
  }

  .react-web--admin__container .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .react-web--admin__container .lg\:mt-14 {
    margin-top: 3.5rem;
  }

  .react-web--admin__container .lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .react-web--admin__container .lg\:block {
    display: block;
  }

  .react-web--admin__container .lg\:flex {
    display: flex;
  }

  .react-web--admin__container .lg\:table-cell {
    display: table-cell;
  }

  .react-web--admin__container .lg\:hidden {
    display: none;
  }

  .react-web--admin__container .lg\:h-full {
    height: 100%;
  }

  .react-web--admin__container .lg\:max-w-none {
    max-width: none;
  }

  .react-web--admin__container .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .react-web--admin__container .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .react-web--admin__container .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .react-web--admin__container .lg\:flex-col {
    flex-direction: column;
  }

  .react-web--admin__container .lg\:gap-8 {
    gap: 2rem;
  }

  .react-web--admin__container .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

